import React from "react";
import firebase from "./../../utils/firebase";
import PubSub from "pubsub-js";
import PaymentService from "./../../services/payment";
// import { useRecoilState } from "recoil";
import localForage from "localforage";
import { connect } from "react-redux";
import { withRouter } from "../../utils/withRouter";
import ReactGA from "react-ga4";
const AuthContext = React.createContext();

var self = null;

class AuthProvider extends React.Component {
  state = {
    user: null,
    authLoading: true,
    userToken: null,
    userTokenResult: null,
    renting: false,
    redirectUrl: null,
    userLoadedCallback: null,
    overlayLoading: "",
    paymentData: null,
  };

  componentDidUpdate() {
    localStorage.authState = JSON.stringify(this.state);
  }
  constructor(props) {
    super(props);
  }
  async componentDidMount() {
    self = this;
    self._mounted = true;
    if (localStorage.authState) {
      this.setState(JSON.parse(localStorage.authState));
    }

    self.refreshUserAuth();
    let redirectUrl = this.props.searchParams.get("redirectUrl");
    console.log("Redirect Url: " + redirectUrl);
    await localForage.setItem("redirectUrl", redirectUrl);
  }

  redirect(url, renting) {
    self.setState({ renting: renting });
    this.props.navigate("/" + url);
  }

  refreshUserAuth() {
    var self = this;
    this.firebaseRequest = firebase.auth().onAuthStateChanged((user) => {
      if (self._mounted) {
        if (user) {
          ReactGA.set({ userId: user.uid });
          self.setState({ user: user });
          firebase
            .auth()
            .currentUser.getIdTokenResult(/* forceRefresh */ true)
            .then((userTokenResult) => {
              self.setState({ userTokenResult: userTokenResult });
              self.setState({ userToken: userTokenResult.token });
              PubSub.publish("userToken", userTokenResult.token);
              PubSub.publish("userTokenLogin", userTokenResult.token);
              localStorage.setItem("userToken", userTokenResult.token);
              if (self.state.userLoadedCallback) {
                self.state.userLoadedCallback();
                self.setState({ userLoadedCallback: null });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          PubSub.publish("user", user);
          localStorage.setItem("user", user);
        } else {
          // clear set
          self.setState({ userTokenResult: null, userToken: null, user: null });
        }
        self.setState({ authLoading: false });
      }
    });
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  makeRedirect = async () => {
    let redirectUrl = (await localForage.getItem("redirectUrl")) || null;
    if (!redirectUrl) {
      redirectUrl = this.props.searchParams.get("redirectUrl");
    }
    redirectUrl = this.props.searchParams.get("redirectUrl");

    if (redirectUrl !== null) {
      this.props.navigate(redirectUrl);
      await localForage.setItem("redirectUrl", null);
      this.setState({ redirectUrl: null });
    }
  };

  canRent = async (redirectUrl, renting = false, noRedirect = false) => {
    // redirectUrl = redirectUrl
    //   ? redirectUrl
    //   : self.props.searchParams.get("redirectUrl");

    self.setState({ redirectUrl: redirectUrl });
    console.log("Redirect Url cant rent: " + redirectUrl);

    // await localForage.setItem("redirectUrl", redirectUrl);
    await self.checkTerms(renting);
  };
  checkTerms = async (renting = false) => {
    console.log(self.state.userTokenResult);
    if (
      !self.state.userTokenResult ||
      !self.state.userTokenResult.claims.acceptedTerms
    ) {
      self.props.navigate(
        `/agreement?redirectUrl=${this.props.searchParams.get("redirectUrl")}`
      );
      self.setState({ renting: renting });
      console.log("navigating to terms");
    } else {
      await self.checkPayments(renting);
    }
  };

  getPayment = async () => {
    var paymentService = new PaymentService(this.state.userToken);
    let currentPaymentMethod = await paymentService.getPayment();
    this.paymentData = currentPaymentMethod;
    this.props.dispatch({ type: "ADD_PAYMENT", payload: this.paymentData });
    return currentPaymentMethod;
  };

  checkPayments = async (renting = false) => {
    let currentPaymentMethod = await this.getPayment();
    if (currentPaymentMethod) {
      if (this.state.redirectUrl) {
        this.props.navigate(this.state.redirectUrl);
      } else {
        this.props.navigate("/");
      }
    } else {
      // no payment info go to payments.
      this.setState({ renting: renting });
      if (this.props.searchParams.get("redirectUrl")) {
        this.props.navigate(
          `/payments?redirectUrl=${this.props.searchParams.get("redirectUrl")}`
        );
      } else {
        this.props.navigate("/payments");
      }
      PubSub.publish("NOTIFICATION", "Please add payment to continue");
      throw "exit";
    }
    return currentPaymentMethod;
  };
  render() {
    return (
      <AuthContext.Provider
        value={{
          user: this.state.user,
          self: this,
          authLoading: this.state.authLoading,
          userToken: this.state.userToken,
          checkPayments: this.checkPayments.bind(this),
          userTokenResult: this.state.userTokenResult,
          canRent: this.canRent.bind(this),
          checkTerms: this.checkTerms.bind(this),
          renting: this.state.renting,
          setState: this.setState.bind(this),
          redirectUrl: this.state.redirectUrl,
          makeRedirect: this.makeRedirect.bind(this),
          redirect: this.redirect.bind(this),
          userLoadedCallback: this.state.userLoadedCallback,
          overlayLoading: this.state.overlayLoading,
          refreshUserAuth: this.refreshUserAuth.bind(this),
          paymentData: this.state.paymentData,
        }}
      >
        {" "}
        {this.props.children}{" "}
      </AuthContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return { paymentData: state.paymentData };
};

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const AuthConsumer = AuthContext.Consumer;
const RouterAuthProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthProvider));
export { RouterAuthProvider, AuthConsumer };

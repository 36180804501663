import { createMuiTheme } from "@material-ui/core/styles";
import { yellow, lime, orange } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#a5d6a7", // same as '#FFCC80', #7DBB23
      main: "#1F9D5C", // same as orange[600]
      dark: "#146D41",
      contrastText: "#fff",
    },
    default: {
      light: lime[200],
      main: lime[600],
      dark: lime[900],
      contrastText: "#fff",
    },
    type: "light",
    secondary: {
      light: orange[200],
      main: "#e38502",
      dark: yellow[900],
      contrastText: "#fff",
    },
    button: "#fff",
  },
  // shadows: [ 'none' ],
  typography: {
    useNextVariants: true,
  },
  button: {
    fontSize: "20px",
  },
  appBar: {
    background: "#4dabf5",
  },
  "@global": {
    button: {
      "&:focus": {
        backgroundColor: "#7cb342",
      },
    },
  },
  hoverColor: lime[500],
  overrides: {
    MuiButton: {
      root: {
        color: "white",
        "&:hover": {
          backgroundColor: "#7cb342",
          color: "white",
        },
        "&:focus": {
          backgroundColor: "#7cb342",
          color: "white",
        },
        "&:disabled": {
          backgroundColor: "#9e9e9e",
          color: "#eeeeee",
        },
        backgroundColor: "#146D41",
      },
    },
  },
});

export default theme;

import React from "react";

import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const history = {
      location: location,
    };

    return (
      <Component
        navigate={navigate}
        params={params}
        history={history}
        searchParams={searchParams}
        {...props}
      />
    );
  };

  return Wrapper;
};

import React, { Component, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LoadingOverlay from "react-loading-overlay";
import AuthService from "./../services/auth";
import {
  RouterAuthProvider,
  AuthConsumer,
} from "./../components/Common/AuthContext";
import { Detector } from "react-detect-offline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
// import { RecoilRoot } from "recoil";
import "rsuite/dist/styles/rsuite-default.css";
import { Provider } from "react-redux";
import theme from "./Theme/Main";
import loadingAnimation from "./../images/Pulse-1s-200px.gif";
import store from "./../state/index";
import { withRouter } from "../utils/withRouter";

const Dashboard = lazy(() => import("./../components/Admin/Dashboard"));
const Wavier = lazy(() => import("./../components/Common/Wavier"));
const Terms = lazy(() => import("./../components/Common/Terms"));
const Privacy = lazy(() => import("./../components/Common/Privacy"));
const AppContainer = lazy(() => import("./../containers/AppContainer"));

class App extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
  }

  checkAuth = () => {
    setTimeout(() => {
      if (!this.authService.userToken) {
        this.props.navigate("/login");
      }
    }, 1000);
  };

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <RouterAuthProvider>
              <Detector
                polling={{
                  // url: "https://www.google.com/",
                  enabled: false,
                  interval: 10000,
                }}
                render={({ online }) => (
                  <AuthConsumer>
                    {({ ...authProps }) => (
                      <React.Fragment>
                        <LoadingOverlay
                          active={authProps.overlayLoading || !online}
                          spinner
                          text={
                            online
                              ? authProps.overlayLoading
                              : "please check your internet connection"
                          }
                        >
                          <Suspense
                            fallback={
                              <div>
                                <img
                                  style={{
                                    width: "100%",
                                  }}
                                  src="https://firebasestorage.googleapis.com/v0/b/rent-a-ball.appspot.com/o/Instagram%20Profile%20Picture.jpg?alt=media&token=2031047a-2a60-428a-8796-8f8ce78ee303"
                                  alt="Loading"
                                ></img>
                                <img
                                  style={{
                                    margin: "0 auto",
                                    display: "block",
                                  }}
                                  src={loadingAnimation}
                                />
                              </div>
                            }
                          >
                            <Routes>
                              <Route
                                path="/admin/*"
                                element={<Dashboard {...authProps} />}
                              />
                              <Route
                                exact
                                path="/wavier"
                                element={<Wavier {...authProps} />}
                              />
                              <Route
                                path="/privacy"
                                element={<Privacy {...authProps} />}
                              />
                              <Route
                                exact
                                path="/terms"
                                element={<Terms {...authProps} />}
                              />
                              <Route
                                path="*"
                                element={
                                  <AppContainer
                                    {...authProps}
                                    checkAuth={this.checkAuth}
                                  />
                                }
                              />
                            </Routes>
                          </Suspense>

                          <CookieConsent>
                            This website uses cookies to enhance the user
                            experience.
                          </CookieConsent>
                          {/* <Route path="/(!admin)" render={() => <Footer />} /> */}
                        </LoadingOverlay>
                      </React.Fragment>
                    )}
                  </AuthConsumer>
                )}
              />
            </RouterAuthProvider>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;

import firebase from "./../utils/firebase";
import PubSub from "pubsub-js";

class AuthService {
  constructor(callback) {
    var self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((userToken) => {
            self.userToken = userToken;
            // Comment this out for now as duplicate user token confuses the authContext
            PubSub.publish("userToken", self.userToken);
            localStorage.setItem("userToken", self.userToken);
            if (callback) {
              callback(userToken);
            }
          })
          .catch((error) => {
            console.log(error);
            callback(false);
          });
        PubSub.publish("user", user);
        localStorage.setItem("user", user);
      } else {
        if (callback) {
          callback(false);
        }
      }
    });
  }
  onUserReady(callback) {
    if (firebase.auth().currentUser) {
      callback();
    }
  }
  getUser() {
    return localStorage.getItem("user");
  }
}

export default AuthService;

import axios from "axios";
import PubSub from "pubsub-js";

class BaseService {
  constructor(url, userToken = null, timeout = 1800000) {
    this.url = url;
    this.timeout = timeout;
    this.userToken = userToken;
    if (this.userToken == null) {
      this.userToken = localStorage.getItem("userToken");
    }
  }

  async getAxiosInstance() {
    const axiosInstance = axios.create({
      baseURL: this.url,
      timeout: this.timeout,
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + this.userToken,
      },
    });
    return axiosInstance;
  }

  handleError(error) {
    console.log(Object.keys(error), error.message);
    let message;
    if (error.response) {
      message = error.response.data.message
        ? error.response.data.message
        : error.message;
    } else {
      message = "Something went wrong =/, please try refreshing";
    }
    PubSub.publish("NOTIFICATION", message);
  }
}

export default BaseService;

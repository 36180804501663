import _ from "lodash";
import { createStore } from "redux";
import tier1 from "./../images/tier1.png";
import tier2 from "./../images/tier2.png";
import tier3 from "./../images/tier3.png";

const initialState = {
  tiers: [
    {
      name: "Rookie",
      description: "Play as needed, and pay as you go",
      price: "Price varies as show on the item",
      planId: "payasyougo",
      selected: true,
      free: true,
      image: tier1,
    },
    {
      name: "Intermediate",
      description: "First 2 Hours Free, unlimited rentals",
      price: "$7.99 per month",
      planId: "f3fb",
      selected: false,
      free: false,
      image: tier2,
    },
    {
      name: "Pro",
      description: "Unlimited Play Time, play all day",
      price: "$16.99 per month",
      planId: "fjh2",
      selected: false,
      free: false,
      image: tier3,
    },
  ],
  loginModal: {
    open: false,
  },
  freePlanId: "payasyougo",
  userPlan: null,
  desiredPlanId: null,
  paymentData: {},
};

const reducer = (state = initialState, action) => {
  if (action.payload && action.type === "ADD_PAYMENT") {
    let newPlan = null;
    if (action.payload.planId && action.payload.subscription) {
      newPlan = _.find(state.tiers, {
        planId: action.payload.subscription.planId,
      });
    }
    if (action.payload.planId == "payasyougo") {
      newPlan = _.find(state.tiers, {
        planId: action.payload.planId,
      });
    }
    return Object.assign({}, state, {
      paymentData: action.payload,
      userPlan: newPlan,
    });
    // _.clone()
  } else if (action.type === "ADD_DESIRED_PLAN") {
    return Object.assign({}, state, { desiredPlanId: action.payload });
  }

  return state;
};

const store = createStore(reducer);

export default store;

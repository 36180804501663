import store from '../state'
import BaseService from './base'
class PaymentService extends BaseService {
  constructor(userToken) {
    super(process.env.REACT_APP_ENGINE_URL, userToken)
  }

  async getPaymentToken() {
    this.service = await this.getAxiosInstance()
    try {
      var res = await this.service.get('/payment_token')
    } catch (error) {
      console.log(Object.keys(error), error.message)
      return null
    }
    return await res.data
  }

  async getPayment() {
    this.service = await this.getAxiosInstance()
    try {
      var res = await this.service.get('/payments')
    } catch (error) {
      console.log(Object.keys(error), error.message)
      return null
    }
    store.dispatch({
      type: 'ADD_PAYMENT',
      payload: res.data,
    })
    return await res.data
  }

  async addPayment(paymentData) {
    this.service = await this.getAxiosInstance()
    try {
      var res = await this.service.post('/payments', paymentData)
    } catch (error) {
      console.log(Object.keys(error), error.message)
      return null
    }
    return await res.data
  }
}

export default PaymentService

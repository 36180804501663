import firebase from "firebase/app";
import "firebase/auth";
import ReactGA from "react-ga4";

if (["dev", "staging"].includes(process.env.REACT_APP_ENV)) {
  var config = {
    apiKey: "AIzaSyBIOYLqdL9aw6mVwzJAHZCI_DwSO_UYmpE",
    authDomain: "rab-stage.firebaseapp.com",
    databaseURL: "https://rab-stage.firebaseio.com",
    projectId: "rab-stage",
    storageBucket: "rab-stage.appspot.com",
    messagingSenderId: "324997377375",
  };
  ReactGA.initialize(
    "G-Q8L48J0YJ1",
    {
      // trackingId:,
      gtagOptions: { 'debug_mode': true }, // optional
    }
  );
} else {
  var config = {
    apiKey: "AIzaSyDkML9OUVl8rlD3sngn95KBiq8Usns3OZ0",
    authDomain: "rent-a-ball.firebaseapp.com",
    databaseURL: "https://rent-a-ball.firebaseio.com",
    projectId: "rent-a-ball",
    storageBucket: "rent-a-ball.appspot.com",
    messagingSenderId: "440648509575",
  };
  ReactGA.initialize(
    "G-B8XKMRB023",
    {
      // trackingId:,
      gtagOptions: { 'debug_mode': true }, // optional
    }
  );
}

firebase.initializeApp(config);

export default firebase;

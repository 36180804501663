import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import registerServiceWorker from './registerServiceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import store from './state/index'
Sentry.init({
    dsn: "https://bf3cbfd89eeb42cda9e4610ea23e83d9@o975157.ingest.sentry.io/5931136",
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
window.store = store

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
